import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { PlusCircleOutlined, MinusCircleOutlined, PaperClipOutlined, ProfileOutlined, DollarOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import getFieldRules from '@/utils/fieldRules';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import { insertContractApply, selectProjectContractByParam, selectTeamMember } from '@/api/contract';
import { selectApprovalItem, selectProfessionalByItem } from '@/api/material';
import Cookies from "js-cookie"; // import moment from 'moment';

const columns = [// {
//   title: '序号',
//   dataIndex: 'id',
//   slots: {
//     customRender: 'id',
//   },
// },
{
  title: '收支节点',
  // className: 'column-money',
  dataIndex: 'payNode',
  fixed: 'left',
  children: [{
    title: '节点描述',
    dataIndex: 'nodeDescriptor',
    // key: 'companyAddress',
    slots: {
      customRender: 'nodeDescriptor'
    },
    width: 300,
    align: 'center'
  }, {
    title: '计划收支时间',
    dataIndex: 'paymentTime',
    // key: 'paymentTime',
    width: 200,
    slots: {
      customRender: 'paymentTime'
    },
    align: 'center'
  }]
}, {
  title: '收支条件',
  dataIndex: 'paymentTerms',
  slots: {
    customRender: 'paymentTerms'
  },
  width: 250,
  align: 'center'
}, {
  title: '收支金额(元)',
  dataIndex: 'paymentAmount',
  slots: {
    customRender: 'paymentAmount'
  },
  width: 150,
  align: 'center'
}, {
  title: '合同总金额(元)',
  dataIndex: 'totalContractAmount',
  slots: {
    customRender: 'totalContractAmount'
  },
  width: 150,
  align: 'center'
}, {
  title: '节点收支比例',
  dataIndex: 'paymentRatio',
  slots: {
    customRender: 'paymentRatio'
  },
  width: 150,
  align: 'center'
}, {
  title: '节点备注',
  dataIndex: 'remark',
  slots: {
    customRender: 'remark'
  },
  width: 250,
  align: 'center'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  },
  align: 'center',
  width: 80
}];
export default defineComponent({
  components: {
    PlusCircleOutlined,
    MinusCircleOutlined,
    PaperClipOutlined,
    uploadImgs,
    ProfileOutlined,
    DollarOutlined
  },
  props: {
    applyVisible: {
      type: Boolean,
      default: false
    },
    contractTypeName: {
      type: Array,
      default: []
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const formRef = ref();

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const formState = reactive({
      theme: "",
      projectName: undefined,
      contractName: '',
      contractAmount: '',
      signContent: '',
      startTime: undefined,
      completionTime: undefined,
      contractType: undefined,
      teamMember: [],
      companyName: '',
      companyContact: '',
      companyMobile: '',
      belongProfessional: undefined,
      fileUrl: [],
      itemId: undefined,
      paymentRecords: [{
        nodeDescriptor: undefined,
        paymentTime: undefined,
        paymentTime: undefined,
        paymentAmount: undefined,
        totalContractAmount: undefined,
        paymentRatio: undefined,
        remark: null
      }],
      contractNoPrefix: null,
      customerContractNo: null
    });
    const {
      checkName,
      checkPhone,
      signFileurl,
      money
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      projectName: [{
        required: true,
        message: '项目名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      contractName: [{
        required: true,
        message: '合同名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      contractAmount: [{
        required: true,
        validator: money,
        trigger: 'blur'
      }],
      startTime: [{
        required: true,
        message: '合同开始时间不能为空',
        trigger: 'change',
        type: 'object'
      }],
      completionTime: [{
        required: true,
        message: '竣工时间不能为空',
        trigger: 'change',
        type: 'object'
      }],
      unloadingRequire: [{
        required: true,
        message: '卸货需求不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      contractName: [{
        required: true,
        message: '归属合同不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      // professional: [
      //   {
      //      required: true,
      //     message: '所属专业不能为空',
      //     trigger: 'change',
      //   },
      // ],
      contractType: [{
        required: true,
        message: '合同类型不能为空',
        trigger: 'change',
        type: 'number'
      }],
      teamMember: [{
        required: true,
        message: '项目组成员不能为空',
        trigger: 'change',
        type: 'array'
      }],
      companyName: [{
        required: true,
        message: '公司名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      companyContact: [{
        required: true,
        validator: checkName,
        // message: '供应商联系人不能为空',
        trigger: 'blur'
      }],
      companyMobile: [{
        required: true,
        validator: checkPhone,
        // message: '联系方式不能为空',
        trigger: 'blur'
      }],
      fileUrl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.fileUrl.length == 0
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    }; //获取附件的url

    const getAdjuncts = val => {
      console.log('getAdjuncts', val);
      formState.fileUrl = val;
    };

    const loading = ref(false); //提交

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        // console.log(findDataIsOk(formState.paymentRecords,formState.paymentRecords.length-1))
        let isOk = findDataIsOk(formState.paymentRecords, formState.paymentRecords.length - 1);

        if (isOk) {
          _message.error('计划收支时间必须顺序填写');

          return;
        }

        let plans = [];
        let sum = 0;
        formState.paymentRecords.forEach((item, index) => {
          sum += item.paymentAmount;
          let obj = {
            nodeDesc: item.nodeDescriptor,
            planPayTime: item.paymentTime.startOf('day').format('YYYY-MM-DD'),
            payTerms: item.paymentTerms,
            payAmount: item.paymentAmount,
            totalAmount: formState.contractAmount,
            payProportion: `${(item.paymentAmount / formState.contractAmount * 100).toFixed(2)}%`,
            contractName: formState.contractName,
            remark: item.remark,
            serialNo: index + 1
          };
          plans.push(obj);
        });

        if (sum !== formState.contractAmount) {
          _message.error('所有节点金额总和必须等于总金额');

          return;
        } // const teamMemberArr = getObj(formState.teamMember)


        const obj = {};
        Object.assign(obj, formState);
        obj.startTime = obj.startTime.startOf('day').format('YYYY-MM-DD');
        obj.completionTime = obj.completionTime.startOf('day').format('YYYY-MM-DD');
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.plans = plans; // obj.teamMember = JSON.stringify(teamMemberArr)

        obj.teamMember = JSON.stringify(formState.teamMember);
        obj.belongProfessional = obj.belongProfessional ? obj.belongProfessional.toString() : null; // console.log(obj, obj.teamMember)

        loading.value = true;
        let res = await insertContractApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);

          loading.value = false;
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const getObj = val => {
      let arrData = [];
      val.forEach(item => {
        // console.log(item)
        let arr = item[2].split('-');
        let obj = {
          idCard: arr[0],
          jobId: item[1],
          belongProject: arr[1],
          userName: arr[2]
        }; // console.log(obj)

        arrData.push(obj);
      });
      return arrData;
    }; // const disabledDate = current => {
    //   return current && current <= moment().subtract(1, 'days');
    // };
    //项目名称查询


    const projectNameData = ref([]);

    const handleSearch = val => {
      fetchForMat(val);
    };

    const fetchForMat = async val => {
      let res = await selectProjectContractByParam({
        projectName: val,
        contractName: null
      }); // console.log(res)

      try {
        projectNameData.value = [];

        if (res.data.length) {
          res.data.forEach(ixt => {
            projectNameData.value.push({
              value: ixt.projectName,
              text: ixt.projectName
            });
          }); //如果当前返回的数据中没有当前的值，则把当前值放进去

          let bool = res.data.some(ixt => ixt.projectName === val);

          if (!bool && val) {
            projectNameData.value.unshift({
              value: val,
              text: val
            });
          }
        } else if (res.data.length === 0 && val) {
          projectNameData.value.push({
            value: val,
            text: val
          });
        }

        projectNameData.value = uniqueJsonArray(projectNameData.value, 'text');
      } catch (error) {
        console.log(error);
      }
    }; //去重


    const uniqueJsonArray = (array, key) => {
      for (var i = 0; i < array.length; i++) {
        for (var j = array.length - 1; j > 0; j--) {
          if (array[i][key] == array[j][key] && i != j) {
            array.splice(j, 1);
          }
        }
      }

      return array;
    }; //合同时间判断


    const handleChangeDate = val => {
      console.log(val);

      if (formState.startTime && formState.completionTime) {
        if (new Date(formState.startTime.startOf('day').format('YYYY-MM-DD')) >= new Date(formState.completionTime.startOf('day').format('YYYY-MM-DD'))) {
          _message.error('竣工时间必须大于开始时间');

          formState[val] = undefined;
          return Promise.reject('竣工时间必须大于开始时间');
        }
      } // console.log(formState.startTime.startOf('day').format('YYYY-MM-DD'),formState.completionTime.startOf('day').format('YYYY-MM-DD'))

    }; //岗位及人员


    const options = ref([]);

    const getJobCascade = async () => {
      let res = await selectTeamMember();

      if (res.code === 200) {
        console.log(res);
        options.value = res.data; // console.log(options.value)
      }
    }; // const getJobCascade = async() => {
    //   const belongProject = Number(Cookies.get("belongProject"))
    //   let res = await selectJobCascade({belongProject})
    //   if(res.code === 200){
    //     // console.log(res)
    //     const jobCorporations = res.data.jobCorporations //总公司管理岗集合
    //     if(Number(Cookies.get("belongProject")) !== 10000){
    //       const jobProjects = res.data.jobProjects //本项目管理岗集合
    //       options.value = [
    //         {
    //           value:Number(Cookies.get("belongProject")),
    //           label: '本项目',
    //           children: conversion(jobProjects)
    //         },
    //         {
    //           value:10000,
    //           label: '总公司',
    //           children: conversion(jobCorporations)
    //         }
    //       ]
    //     }else {
    //       options.value = conversion(jobCorporations)
    //     }
    //     // console.log(options.value)
    //   }
    // } 


    const conversion = val => {
      const arr = [];
      val.forEach(item => {
        let obj = {
          value: item.jobId,
          label: item.jobName,
          children: []
        };

        if (item.hyEmployees.length) {
          item.hyEmployees.forEach(ixt => {
            obj.children.push({
              value: `${ixt.uid}-${ixt.belongProject}-${ixt.userName}`,
              label: ixt.userName,
              projectId: ixt.belongProject
            });
          });
        }

        arr.push(obj);
      });
      return arr;
    };

    const onDelete = key => {
      // formState.paymentRecords =formState.paymentRecords.filter(item => item.key !== key);
      formState.paymentRecords.splice(key, 1);
    };

    const handleAdd = () => {
      const newData = {
        nodeDescriptor: undefined,
        paymentTime: undefined,
        paymentTime: undefined,
        paymentAmount: undefined,
        totalContractAmount: formState.contractAmount,
        paymentRatio: undefined,
        remark: null
      };
      formState.paymentRecords.push(newData);
    };

    const findDataIsOk = (arr, i) => {
      if (i <= 0) {
        return false;
      } else {
        if (new Date(arr[i].paymentTime.startOf('day').format('YYYY-MM-DD')) > new Date(arr[i - 1].paymentTime.startOf('day').format('YYYY-MM-DD'))) {
          return findDataIsOk(arr, i - 1);
        } else {
          return true;
        }
      }
    };

    const getTotalContractAmount = val => {
      val = `￥ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return val;
    }; //所属项目查询


    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 4,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //所属专业


    const professionalOptions = ref([]);

    const getProfessionalByItem = async () => {
      let res = await selectProfessionalByItem({
        itemId: formState.itemId
      });

      if (res.code === 200) {
        professionalOptions.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            professionalOptions.value.push({
              value: item.professionalName,
              label: item.professionalName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    onMounted(() => {
      getApprovalItem();
      getJobCascade();
    });
    return {
      closeApplyVisible,
      formRef,
      formState,
      rules,
      handleOk,
      loading,
      getAdjuncts,
      // disabledDate,
      handleSearch,
      projectNameData,
      handleChangeDate,
      options,
      columns,
      onDelete,
      handleAdd,
      getTotalContractAmount,
      money,
      projectOptions,
      professionalOptions,
      getProfessionalByItem
    };
  }

});