import "ant-design-vue/es/message/style/css";
import _message from "ant-design-vue/es/message";
import { defineComponent, ref, reactive, computed, watch, onMounted } from 'vue';
import { ProfileOutlined, DollarOutlined, BranchesOutlined, PartitionOutlined, PaperClipOutlined } from '@ant-design/icons-vue';
import { contractSelectApplyById, selectTeamMember, updateToContractApproval, updateContractApply, updateReviseApply, updateTeamByApplyId } from '@/api/contract';
import { selectApprovalItem, selectProfessionalByItem } from '@/api/material';
import uploadImgs from '@/views/dataManagement/components/uploadImgs.vue';
import getFieldRules from '@/utils/fieldRules';
import moment from 'moment';
import Cookies from "js-cookie";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
const columns = [// {
//   title: '序号',
//   dataIndex: 'id',
//   slots: {
//     customRender: 'id',
//   },
// },
{
  title: '收支节点',
  // className: 'column-money',
  dataIndex: 'payNode',
  fixed: 'left',
  children: [{
    title: '节点描述',
    dataIndex: 'nodeDesc',
    // key: 'companyAddress',
    slots: {
      customRender: 'nodeDesc'
    },
    width: 300,
    align: 'center'
  }, {
    title: '计划收支时间',
    dataIndex: 'planPayTime',
    width: 200,
    align: 'center',
    // key: 'planPayTime',
    slots: {
      customRender: 'planPayTime'
    }
  }]
}, {
  title: '收支条件',
  dataIndex: 'payTerms',
  width: 250,
  align: 'center',
  slots: {
    customRender: 'payTerms'
  }
}, {
  title: '收支金额(元)',
  dataIndex: 'payAmount',
  slots: {
    customRender: 'payAmount'
  },
  width: 150,
  align: 'center'
}, {
  title: '合同总金额(元)',
  dataIndex: 'totalAmount',
  slots: {
    customRender: 'totalAmount'
  },
  width: 150,
  align: 'center'
}, {
  title: '节点收支比例',
  dataIndex: 'payProportion',
  slots: {
    customRender: 'payProportion'
  },
  width: 150,
  align: 'center'
}, {
  title: '节点备注',
  dataIndex: 'remark',
  slots: {
    customRender: 'remark'
  },
  width: 250,
  align: 'center'
}, {
  title: '操作',
  dataIndex: 'operation',
  slots: {
    customRender: 'operation'
  },
  width: 80,
  align: 'center'
}];
const columnForChange = [{
  title: '变更单号',
  dataIndex: 'changeNo'
}, {
  title: '变更名称',
  dataIndex: 'theme'
}, {
  title: '变更时间',
  dataIndex: 'createTime'
}, {
  title: '变更金额',
  dataIndex: 'changeAmount',
  slots: {
    customRender: 'changeAmount'
  }
}, {
  title: '变更单',
  dataIndex: 'fileUrl',
  slots: {
    customRender: 'fileUrl'
  }
}];
export default defineComponent({
  components: {
    ProfileOutlined,
    DollarOutlined,
    uploadImgs,
    BranchesOutlined,
    PartitionOutlined,
    PaperClipOutlined
  },
  props: {
    // applyVisible: {
    //   type: Boolean,
    //   default: false
    // },
    applyDetailData: {
      type: Object,
      default: () => {}
    },
    contractTypeName: {
      type: Array,
      default: []
    }
  },
  emits: ['closeApplyVisible'],

  setup(props, context) {
    const store = useStore();
    const formRef = ref();
    const formState = reactive({
      theme: "",
      projectName: undefined,
      contractName: '',
      contractAmount: '',
      signContent: '',
      startTime: undefined,
      completionTime: undefined,
      contractType: undefined,
      teamMember: [],
      companyName: '',
      companyContact: '',
      companyMobile: '',
      belongProfessional: undefined,
      fileUrl: [],
      itemId: undefined,
      plans: [{
        nodeDesc: undefined,
        planPayTime: undefined,
        payTerms: undefined,
        payAmount: undefined,
        totalAmount: undefined,
        payProportion: undefined,
        contractName: undefined,
        remark: null
      }],
      // approvalProcedureDtos:[], //审批流程
      approvalDtos: [],
      hyApproachApprovals: [],
      resource: 0,
      content: "",
      //审批意见
      annotation: [],
      //批注后文件
      revisePayroll: [],
      //修订后合同
      updateContractList: [],
      //修订后合同集
      changeList: [],
      //变更单
      reviseCon: [],
      //修改时需要修改修订后合同时用
      contractNoPrefix: null,
      customerContractNo: null
    });
    const {
      checkName,
      checkPhone,
      signFileurl,
      money
    } = getFieldRules();
    const rules = {
      theme: [{
        required: true,
        message: '申请主题不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      projectName: [{
        required: true,
        message: '项目名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      contractName: [{
        required: true,
        message: '合同名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      contractAmount: [{
        required: true,
        validator: money,
        trigger: 'blur'
      }],
      startTime: [{
        required: true,
        message: '合同开始时间不能为空',
        trigger: 'change',
        type: 'object'
      }],
      completionTime: [{
        required: true,
        message: '竣工时间不能为空',
        trigger: 'change',
        type: 'object'
      }],
      unloadingRequire: [{
        required: true,
        message: '卸货需求不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      contractName: [{
        required: true,
        message: '归属合同不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      // professional: [
      //   {
      //      required: true,
      //     message: '所属专业不能为空',
      //     trigger: 'change',
      //   },
      // ],
      contractType: [{
        required: true,
        message: '合同类型不能为空',
        trigger: 'change',
        type: 'number'
      }],
      teamMember: [{
        required: true,
        message: '项目组成员不能为空',
        trigger: 'change',
        type: 'array'
      }],
      companyName: [{
        required: true,
        message: '公司名称不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      companyContact: [{
        required: true,
        validator: checkName,
        // message: '供应商联系人不能为空',
        trigger: 'blur'
      }],
      companyMobile: [{
        required: true,
        validator: checkPhone,
        // message: '联系方式不能为空',
        trigger: 'blur'
      }],
      fileUrl: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.fileUrl.length == 0
      }],
      revisePayroll: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.revisePayroll.length == 0
      }],
      reviseCon: [{
        required: true,
        validator: signFileurl,
        type: 'array',
        // trigger: 'change',
        trigger: formState.reviseCon.length == 0
      }],
      content: [{
        required: true,
        message: '审批意见不能为空',
        trigger: 'blur'
      }, {
        pattern: /^[^\s]*$/,
        message: '禁止输入空格',
        trigger: 'blur'
      }],
      itemId: [{
        required: true,
        message: '审批流程线不能为空',
        type: 'number',
        trigger: 'change'
      }]
    };

    const closeApplyVisible = () => {
      formRef.value.resetFields();
      context.emit('closeApplyVisible', {
        isClose: false,
        state: 1
      });
    };

    const isDisabledForCl = computed(() => {
      if (props.applyDetailData.state === 0) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 2) {
        return false;
      } else if (props.applyDetailData.state == 20) {
        return false;
      }
    });
    const isDisabledForSp = computed(() => {
      if (props.applyDetailData.state === 0) {
        return true;
      } else if (props.applyDetailData.state == 1) {
        return true;
      } else if (props.applyDetailData.state == 2) {
        return true;
      } else if (props.applyDetailData.state == 20) {
        return true;
      }
    });
    const isShowBianBtn = ref(-1);
    const isShowShouBtn = ref(-1);
    const isShowJieBtn = ref(-1);

    const getData = async key => {
      const res = await contractSelectApplyById(key);

      try {
        Object.assign(formState, res.data);
        formState.fileUrl = JSON.parse(formState.fileUrl);
        formState.teamMember = JSON.parse(formState.teamMember);
        formState.belongProfessional = formState.belongProfessional ? formState.belongProfessional.split(',') : undefined;
        formState.startTime = moment(formState.startTime, 'YYYY-MM-DD');
        formState.completionTime = moment(formState.completionTime, 'YYYY-MM-DD');
        formState.plans.forEach(item => {
          item.key = item.serialNo;
          item.planPayTime = moment(item.planPayTime, 'YYYY-MM-DD');
        });
        formState.reviseCon = formState.updateContractList.length ? JSON.parse(formState.updateContractList[formState.updateContractList.length - 1].fileUrl) : [];
        formState.revisePayroll = formState.revisePayroll == null ? [] : formState.revisePayroll;

        if (formState.state == 22) {
          // console.log(store.state.user.menus)
          let isShow = formState.teamMember.findIndex(item => item[1] === Cookies.get('uid'));

          if (isShow !== -1) {
            store.state.user.menus.some(item => {
              if (item.menuName === "approval-process" && item.childMenuList.length) {
                isShowBianBtn.value = item.childMenuList.findIndex(ixt => ixt.menuName == "changeApproval");
                isShowShouBtn.value = item.childMenuList.findIndex(ixt => ixt.menuName == "paymentApproval");
                isShowJieBtn.value = item.childMenuList.findIndex(ixt => ixt.menuName == "settlementApproval");
              }
            });
          }
        }

        console.log(formState);
      } catch (error) {
        console.log('合同详情:', error);
      }
    }; //岗位及人员


    const options = ref([]);

    const getJobCascade = async () => {
      let res = await selectTeamMember();

      if (res.code === 200) {
        // console.log(res)
        options.value = res.data; // console.log(options.value)
      }
    };

    const getTotalContractAmount = val => {
      val = `￥ ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return val;
    };

    const onDelete = key => {
      // formState.paymentRecords =formState.paymentRecords.filter(item => item.key !== key);
      formState.plans.splice(key, 1);
    };

    const handleAdd = () => {
      const newData = {
        nodeDesc: undefined,
        planPayTime: undefined,
        payTerms: undefined,
        payAmount: undefined,
        payProportion: undefined,
        totalAmount: formState.contractAmount,
        contractName: undefined,
        remark: null
      };
      formState.plans.push(newData);
    }; //获取附件的url


    const getAdjuncts = val => {
      // console.log('getAdjuncts',val)
      formState.fileUrl = val;
      formRef.value.clearValidate(['fileUrl']);
    };

    const getAdjuncts1 = val => {
      // console.log('getAdjuncts',val)
      formState.annotation = val;
    };

    const getAdjunctsForRev = val => {
      // console.log('getAdjuncts',val)
      formState.revisePayroll = val;
      formRef.value.clearValidate(['revisePayroll']);
    };

    const getAdjunctsForRevCon = val => {
      // console.log('getAdjuncts',val)
      formState.reviseCon = val;
      formRef.value.clearValidate(['reviseCon']);
    }; //审批


    const handlePassOrReject = async num => {
      if (!formState.content && formState.resource == 1) {
        _message.error('审批意见不能为空！');

        return;
      }

      const param = {
        applyId: formState.applyId,
        content: formState.content,
        state: num,
        belongProject: formState.belongProject,
        fileUrl: JSON.stringify(formState.annotation)
      };
      const res = await updateToContractApproval(param);

      if (res.code == 200) {
        _message.success('审批成功！');

        context.emit('closeApplyVisible', {
          isClose: false,
          state: 2
        });
      } else {
        _message.error(res.message);
      }
    }; //审批员审批相关


    const getCurrent = arr => {
      const newArr = arr.filter(item => item.status == 1);
      return newArr.length - 1;
    };

    const setRoleType = num => {
      switch (num) {
        case 0:
          return "申请人";

        case 1:
          return "专业负责人";

        case 2:
          return "库管员";

        case 3:
          return "其他审批人";

        case 4:
          return "财务审批人";
      }
    }; //设置步骤条样式


    const setStatus = val => {
      const isError = val.some(item => item.isReject === 1);

      if (isError) {
        return 'error';
      }
    };

    const loading = ref(false); //修改

    const handleOk = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        // console.log(findDataIsOk(formState.paymentRecords,formState.paymentRecords.length-1))
        let isOk = findDataIsOk(formState.plans, formState.plans.length - 1);

        if (isOk) {
          _message.error('计划收支时间必须顺序填写');

          return;
        }

        let plans = [];
        let sum = 0;
        formState.plans.forEach((item, index) => {
          sum += item.payAmount;
          let obj = {
            nodeDesc: item.nodeDesc,
            planPayTime: item.planPayTime.startOf('day').format('YYYY-MM-DD'),
            payTerms: item.payTerms,
            payAmount: item.payAmount,
            totalAmount: formState.contractAmount,
            payProportion: `${(item.payAmount / formState.contractAmount * 100).toFixed(2)}%`,
            contractName: formState.contractName,
            remark: item.remark,
            serialNo: index + 1
          };
          plans.push(obj);
        });

        if (sum !== formState.contractAmount) {
          _message.error('所有节点金额总和必须等于总金额');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        obj.startTime = obj.startTime.startOf('day').format('YYYY-MM-DD');
        obj.completionTime = obj.completionTime.startOf('day').format('YYYY-MM-DD');
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.revisePayroll = JSON.stringify(obj.reviseCon);
        obj.plans = plans; // obj.teamMember = JSON.stringify(teamMemberArr)

        obj.teamMember = JSON.stringify(formState.teamMember);
        obj.belongProfessional = obj.belongProfessional ? obj.belongProfessional.toString() : null; // console.log(obj, obj.teamMember)

        loading.value = true;
        let res = await updateContractApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    }; //修订


    const handleRevise = () => {
      // console.log(formState)
      formRef.value.validate().then(async () => {
        // console.log(findDataIsOk(formState.paymentRecords,formState.paymentRecords.length-1))
        let isOk = findDataIsOk(formState.plans, formState.plans.length - 1);

        if (isOk) {
          _message.error('计划收支时间必须顺序填写');

          return;
        }

        let plans = [];
        let sum = 0;
        formState.plans.forEach((item, index) => {
          sum += item.payAmount;
          let obj = {
            nodeDesc: item.nodeDesc,
            planPayTime: item.planPayTime.startOf('day').format('YYYY-MM-DD'),
            payTerms: item.payTerms,
            payAmount: item.payAmount,
            totalAmount: formState.contractAmount,
            payProportion: `${(item.payAmount / formState.contractAmount * 100).toFixed(2)}%`,
            contractName: formState.contractName,
            remark: item.remark,
            serialNo: index + 1
          };
          plans.push(obj);
        });

        if (sum !== formState.contractAmount) {
          _message.error('所有节点金额总和必须等于总金额');

          return;
        }

        const obj = {};
        Object.assign(obj, formState);
        obj.startTime = obj.startTime.startOf('day').format('YYYY-MM-DD');
        obj.completionTime = obj.completionTime.startOf('day').format('YYYY-MM-DD');
        obj.fileUrl = JSON.stringify(obj.fileUrl);
        obj.revisePayroll = JSON.stringify(obj.revisePayroll);
        obj.plans = plans; // obj.teamMember = JSON.stringify(teamMemberArr)

        obj.teamMember = JSON.stringify(formState.teamMember);
        obj.belongProfessional = obj.belongProfessional ? obj.belongProfessional.toString() : null; // console.log(obj, obj.teamMember)

        loading.value = true;
        let res = await updateReviseApply(obj);

        if (res.code === 200) {
          _message.success(res.message);

          formRef.value.resetFields();
          context.emit('closeApplyVisible', {
            isClose: false,
            state: 2
          });
        } else {
          _message.error(res.message);
        }

        loading.value = false;
      }).catch(error => {
        console.log('error', error);
      });
    };

    const findDataIsOk = (arr, i) => {
      if (i <= 0) {
        return false;
      } else {
        if (new Date(arr[i].planPayTime.startOf('day').format('YYYY-MM-DD')) > new Date(arr[i - 1].planPayTime.startOf('day').format('YYYY-MM-DD'))) {
          return findDataIsOk(arr, i - 1);
        } else {
          return true;
        }
      }
    }; //修改成员


    const handleModifyTeamMember = async () => {
      const param = {
        teamMember: JSON.stringify(formState.teamMember),
        id: formState.applyId
      };
      const res = await updateTeamByApplyId(param);

      if (res.code == 200) {
        _message.success(res.message);
      } else {
        _message.error(res.message);
      }
    }; //修改模式是否显示最后一次修订合同是否可修改


    const isModifyForCot = index => {
      if (formState.updateContractList.length && props.applyDetailData.state == 2 && index == formState.updateContractList.length - 1) {
        return false;
      } else {
        return true;
      }
    }; //修改模式是否显示变更单是否显示可修改


    const isShowFileUrlByUp = computed(() => {
      if (props.applyDetailData.state == 2) {
        if (formState.updateContractList.length) {
          return false;
        } else {
          return true;
        }
      } else if (props.applyDetailData.state !== 2) {
        return false;
      }
    });
    const isShowFileUrl = computed(() => {
      if (props.applyDetailData.state == 2) {
        if (formState.updateContractList.length) {
          return true;
        } else {
          return false;
        }
      } else if (props.applyDetailData.state !== 2) {
        return true;
      }
    }); //所属项目查询

    const projectOptions = ref([]);

    const getApprovalItem = async () => {
      const param = {
        type: 4,
        belongProject: Number(Cookies.get("belongProject"))
      };
      let res = await selectApprovalItem(param);

      if (res.code === 200) {
        projectOptions.value = [];

        if (res.data.approvalItems && res.data.approvalItems.length) {
          res.data.approvalItems.forEach(item => {
            projectOptions.value.push({
              value: item.id,
              label: item.itemName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    }; //所属专业


    const professionalOptions = ref([]);

    const getProfessionalByItem = async () => {
      let res = await selectProfessionalByItem({
        itemId: formState.itemId
      });

      if (res.code === 200) {
        professionalOptions.value = [];

        if (res.data && res.data.length) {
          res.data.forEach(item => {
            professionalOptions.value.push({
              value: item.professionalName,
              label: item.professionalName
            });
          });
        }
      } else {
        _message.error(res.message);
      }
    };

    const lineIsShowFileUrl = val => {
      let arr = JSON.parse(val);
      console.log(arr);

      if (arr && arr.length) {
        return true;
      } else {
        return false;
      }
    };

    const router = useRouter();

    const goPage = (path, name) => {
      store.dispatch(name, true);
      router.push({
        name: path
      });
    };

    onMounted(() => {
      getData(props.applyDetailData.key);
      getJobCascade();
      getApprovalItem();
    });
    return {
      formRef,
      formState,
      rules,
      isDisabledForCl,
      isDisabledForSp,
      closeApplyVisible,
      options,
      columns,
      getTotalContractAmount,
      onDelete,
      handleAdd,
      getAdjuncts,
      getAdjuncts1,
      handlePassOrReject,
      getCurrent,
      setRoleType,
      setStatus,
      handleOk,
      money,
      loading,
      getAdjunctsForRev,
      handleRevise,
      handleModifyTeamMember,
      isModifyForCot,
      columnForChange,
      isShowFileUrlByUp,
      isShowFileUrl,
      getAdjunctsForRevCon,
      projectOptions,
      professionalOptions,
      getProfessionalByItem,
      lineIsShowFileUrl,
      isShowBianBtn,
      isShowShouBtn,
      isShowJieBtn,
      goPage
    };
  }

});